/*  ==========================================================================
    QUOTE
    ========================================================================== */

/*
 * COLORS
 */

$module-clr__quote--border: $clr__holzer--logo;
$module-clr__quote--signet: $clr__holzer--logo;
$module-clr__quote--text: $clr__white;

/*
 * MODULE
 */

.bn-quote {
  @media only screen and (max-width: $bn-grid__bp--phone) {
    padding-top: 15px;
  }
  &.bn-quote--2 {
    .bn-quote__frame {
      max-width: $bn-grid__size--s;
    }

    .bn-quote__text {
      color: $clr__holzer--logo;
    }

    .bn-quote__author {
      color: $clr__holzer--logo !important;
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
  .bn-grid__col {
    display: flex;
    justify-content: center;
  }

  .bn-quote__frame {
    border-bottom: 1px solid $module-clr__quote--border;
    border-top: 1px solid $module-clr__quote--border;
    padding: 30px 0;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    &:not(.--items-1) {
      width: calc(100% - 80px);

      .bn-quote__items {
        width: calc(100% + 80px);
      }
    }

    .bn-quote__items {
      display: flex;
      align-items: center;
      justify-content: center;

      .bn-quote__item {
        flex: 1;

        &.--gold {
          * {
            color: $module-clr__quote--signet;
          }
        }
      }

      .bn-arrows__arrow {
        font-size: 32px;
        color: $module-clr__quote--signet;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .bn-quote__text {
      @include typo--quote($module-clr__quote--text);
      text-align: center;
      font-weight: 100;
      a {
        color: $clr__holzer--logo;
        text-decoration: underline;
      }
    }

    .bn-quote__author {
      &::before {
        content: "- ";
      }
      &::after {
        content: " -";
      }
      @include typo--quote-author($module-clr__quote--text);
      margin-top: 20px;
      text-align: center;
      font-weight: bold;
    }

    .bn-quote__signet {
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transform: translateY(-50%);
      width: 100%;
      z-index: 5;

      svg {
        background-color: #2f3b45;
        height: 45px;
        padding: 0 15px;
        margin-bottom: 32px;
        position: relative;

        * {
          fill: $module-clr__quote--signet;
        }
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// phablet
@media #{$mq__phablet} {
  .bn-quote {
    .bn-quote__frame {
      padding: 20px 0;

      .bn-quote__author {
        margin-top: 10px;
      }

      .bn-quote__signet {
        .bn-icon {
          font-size: 4.5em;
        }
      }

      .bn-quote__items {
        .bn-arrows__arrow {
          font-size: 22px;
        }
      }
    }
  }
}