/*  ==========================================================================
    SIDEBAR
    ========================================================================== */

@mixin header--sidebar() {
  background-color: $clr__ci--primary;
  display: none;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: $unit__wdt--header-v3-sidebar-desktop-large;
  z-index: $unit__zi--header-v3-sidebar;
  a {
    color: $clr__white;
    &:visited {
      color: $clr__white;
    }
    &:hover {
      color: $clr__white;
    }
  }

  .bn-sidebar__top,
  .bn-sidebar__center,
  .bn-sidebar__bottom {
    display: flex;
    width: 100%;
  }
  .bn-sidebar__top,
  .bn-sidebar__bottom {
    flex: none;
  }
  .bn-sidebar__top {
    padding-bottom: 20px;
  }
  .bn-sidebar__center {
    @include scrollbar($clr__scrollbar,5px);
    flex: 1;
    overflow-y: auto;
  }
  .bn-sidebar__bottom {
    padding-top: 20px;
  }
  // close
  .bn-close {
    color: rgba($clr__typo--secondary, 0.5);
    cursor: pointer;
    display: inline-block;
    pointer-events: initial;
    .bn-icon {
      font-size: 3.5em;
      line-height: 50px;
    }
    &:hover {
      color: $clr__typo--secondary;
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--aside {
      margin-top: 40px;
      max-width: 50%;
      img {
        @include img--rendering-crisp-edges();
        max-width: 100%;
      }
      .bn-main-logo__image {
        &.bn-main-logo__image--big {
          display: none;
        }
        &.bn-main-logo__image--small {
          display: inline-block;
          img {
            height: 80px;
          }
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
  }
  // main menu
  .bn-main-menu {
    .bn-main-menu__lvl--0 {
      padding-left: 0;
      list-style-type: none;
      .bn-main-menu__lvl--1 {
        > .bn-main-menu__item {
          a {
            color: rgba($clr__white, 0.6);
            &:visited {
              color: $clr__white;
            }
            &:hover {
              color: $clr__white;
            }
          }
        }
      }
      .bn-main-menu__lvl--2 {
        > .bn-main-menu__item {
          a {
            color: rgba($clr__white, 0.6);
            &:visited {
              color: $clr__white;
            }
            &:hover {
              color: $clr__white;
            }
          }
        }
      }
      .bn-main-menu__item {
        position: relative;
        &:before {
          background-color: $clr__ci--primary-light;
          content: '';
          display: none;
          left: -40px;
          height: 100%;
          position: absolute;
          top: 0;
          width: calc(100% + 40px);
        }
        > .bn-main-menu__link {
          position: relative;
          z-index: 5;
        }
        &:hover {
          &:before {
            display: block;
          }
          > .bn-main-menu__link {
            color: rgba($clr__white, 0.8) !important;
          }
        }
        &.bn-main-menu__item--act,
        &.bn-main-menu__item--cur {
          > .bn-main-menu__link {
            color: #dcc27d;
          }
        }
      }
      > .bn-main-menu__item {
        > .bn-main-menu__link {
          display: block;
          font-family: $typo__ff--primary;
          padding: 20px 20px 20px 0;
          > .bn-main-menu__titles {
            .bn-main-menu__title,
            .bn-main-menu__subtitle {
              text-transform: uppercase;
            }
            .bn-main-menu__title {
              font-size: 2.5em;
              display: flex;
              align-items: center;
            }
            .bn-main-menu__subtitle {
              font-size: 2.0em;
            }
          }
          .bn-sub-menu-trigger {
            display: none;
          }
        }
        .bn-main-menu__sub-menu {
          > .bn-main-menu__arrow {
            display: none;
          }
          .bn-main-menu__lvl-wrap {
            display: none;
            height: 100vh;
            position: fixed;
            top: 0;
            width: $unit__wdt--header-v3-sub-lvl-wrap-desktop-large;
            z-index: $unit__zi--header-v3-sub-menu-wrap;
            &:before {
              background-color: $clr__white;
              content: '';
              display: block;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              width: calc(100% - 20px);
            }
            > div {
              height: 100%;
              position: relative;
              margin-left: 20px;
              width: calc(100% - 20px);
              z-index: 5;
            }
            .bn-main-menu__lvl {
              @include scrollbar($clr__scrollbar,5px);
              list-style-type: none;
              max-height: 100%;
              overflow-y: auto;
              padding: 30px 0 30px 40px;
              .bn-main-menu__item {
                .bn-main-menu__link {
                  display: block;
                  font-size: 2.0em;
                  padding: 10px 40px 10px 0;
                  font-family: $typo__ff--primary;
                }
              }
            }
            &.bn-main-menu__lvl-wrap--1 {
              left: calc(#{$unit__wdt--header-v3-sidebar-desktop-large} - 20px);
              > div {
                background-color: $clr__ci--primary-light;
              }
            }
            &.bn-main-menu__lvl-wrap--2 {
              left: calc(#{$unit__wdt--header-v3-sidebar-desktop-large} + #{$unit__wdt--header-v3-sub-lvl-wrap-desktop-large} - 40px);
              > div {
                background-color: $clr__ci--primary-light;
              }
            }
          }
        }
        &:hover {
          > .bn-main-menu__link {
            color: $clr__typo--secondary;
          }
          > .bn-main-menu__sub-menu {
            > .bn-main-menu__lvl-wrap {
              display: block;
              .bn-main-menu__lvl {
                .bn-main-menu__item {
                  &:hover {
                    > .bn-main-menu__link {
                      color: $clr__typo--secondary;
                    }
                    > .bn-main-menu__lvl-wrap {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // top references
  .bn-top-references {
    &.bn-top-references--aside {
      font-size: 2.5em;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      a {
        color: $clr__cta--primary;
        transition: color 0.3s;
      }
      img {
        max-width: 23px;
        width: auto;
        padding: 0;
        filter: contrast(0) sepia(1) invert(0) hue-rotate(362deg) saturate(165%);
      }
      .socialWidget.cx_brand_refresh1 .socialWidgetCore.sm {
        width: 38px;
        height: 38px;
        background-color: transparent!important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #dcc27d;
      }
      .TA_socialButtonIcon {
        ul {
          padding: 0;
        }
      }
      &:hover {
        a {
          color: rgba($clr__cta--primary, 0.7);
        }
      }
      .bn-top-references__container {
        &:last-child {
          margin-left: 0;
        }
        .bn-top-references__item {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-sizing: content-box;
          &:hover {
            color: $clr__cta--primary !important;
          }

          &.bn-top-references__item--trigger {
            display: none;
          }
          i.bn-icon {
            font-size: 38px;
          }
        }
      }
      .bn-top-references__description {
        display: none;
      }
      .bn-top-references__item.bn-top-references__item--trigger  {
        display: none;
      }
    }
  }
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--aside {
      ul {
        @include clearfix();
        font-size: 1.6em;
        list-style-type: none;
        padding-left: 0;
        li {
          float: left;
          &:before {
            content: '|';
          }
          a {
            padding: 0 5px;
            &:hover {
              opacity: 0.9;
            }
          }
          &.bn-lang-menu__item--act {
            a {
              color: $clr__typo--secondary;
              font-weight: $typo__fw--bold;
            }
          }
          &:first-child {
            border-left: 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  // tel
  .bn-tel {
    &.bn-tel--aside {
      font-size: 2em;
      margin-top: 20px;
      text-align: center;
      i.bn-icon.bn-icon--phone {
        display: none;
      }
    }
  }
  // call to action
  .bn-call-to-action--container {
    display: flex;
    justify-content: center;
    gap: 3em;
  }
  .bn-call-to-action {
    &.bn-call-to-action--aside {
      margin-top: 4em;
      display: flex;
      justify-content: center;
      .bn-button {
        margin: 0 5px;
        &.bn-button--inquiry,
        &.bn-button--book {
          @include button--cta();
          padding: 15px 35px;
          letter-spacing: .8px;
          font-size: 14px;
          min-width: 0;
          line-height: 18px;
          border-radius: 50px;
          display: inline-flex;
          align-items: center;
          font-weight: bold;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  // open
  &.bn-sidebar--open {
    display: flex;
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // desktop medium
  @media #{$mq__desktop--medium} {
    width: $unit__wdt--header-v3-sidebar;
    min-width: 330px;
    // main menu
    .bn-main-menu {
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              width: $unit__wdt--header-v3-sub-lvl-wrap;
              &.bn-main-menu__lvl-wrap--1 {
                left: calc(#{$unit__wdt--header-v3-sidebar} - 20px);
              }
              &.bn-main-menu__lvl-wrap--2 {
                left: calc(#{$unit__wdt--header-v3-sidebar} + #{$unit__wdt--header-v3-sub-lvl-wrap} - 40px);
              }
            }
          }
        }
      }
    }
  }
  // laptop
  @media #{$mq__laptop} {
    .bn-sidebar__top {
      padding: 20px 20px 10px 20px;
    }
    .bn-sidebar__center {
      padding-left: 20px;
    }
    .bn-sidebar__bottom {
      padding: 10px 20px 20px 20px;
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--aside {
        margin-top: 20px;
      }
    }
    // main menu
    .bn-main-menu {
      .bn-main-menu__item {
        &:before {
          left: -20px;
          width: calc(100% + 20px);
        }
      }
      .bn-main-menu__lvl--0 {
        > .bn-main-menu__item {
          > .bn-main-menu__link {
            padding: 10px 20px 10px 0;
          }
          .bn-main-menu__sub-menu {
            .bn-main-menu__lvl-wrap {
              .bn-main-menu__lvl {
                padding: 10px 0 10px 20px;
                .bn-main-menu__item {
                  .bn-main-menu__link {
                    padding: 10px 20px 10px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}