/*  ==========================================================================
    COLORS
    List of all colors concerning your project.

    INFO:
    - change your project related colors here
    - if needed change also the specific colors
    - don't delete colors, only add (if possible here: custom)!
    - for naming help of additional custom colors use
      "http://chir.ag/projects/name-that-color/#000000"
    ========================================================================== */

/*
 * GENERAL
 */

$clr__black: #000000;
$clr__bn-red: #AE173B;
$clr__gray: #808080;
$clr__white: #FFFFFF;

/*
 * PROJECT RELATED
 */

// corporate identity
$clr__ci--primary: #2F3B45;
$clr__ci--primary-light: #425765;
$clr__ci--secondary: #F1F0EA;
$clr__ci--tertiary: #F1F0EA;

// typography
$clr__typo--primary: #FFFFFF;
$clr__typo--secondary: #FFFFFF;
$clr__typo--tertiary: #2F3B45;

// call to action
$clr__cta--primary: #DCC27D;
$clr__cta--hover: #DDA741;

// notification
$clr__correct: #008000;
$clr__error: #FF0000;

/*
 * SPECIFIC
 */

$clr__page: $clr__ci--primary;
$clr__progress-bar: $clr__cta--primary;
$clr__scrollbar: $clr__cta--primary;
$clr__selection: $clr__ci--primary;

/*
 * OPTIONS
 */
//Bei true haben Elemente einen Border, bei false einen schatten
$tgl__use-border: true;
//Derzeit nur bei bn-top-references footer verbaut

/*
 * CUSTOM
 */

$clr__holzer--logo: $clr__cta--primary;