/*  ==========================================================================
    HEADER V3
    ========================================================================== */

@mixin header--v3() {
  background: linear-gradient(rgba($clr__black,0.4), transparent);
  left: 0;
  min-height: $unit__hgt--header-v3;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  // controls
  .bn-controls {
    color: $clr__white;
    .bn-controls__left,
    .bn-controls__center,
    .bn-controls__right {
      padding: 40px 0 40px 0;
    }
    .bn-controls__center {
      text-align: center;
      max-width: 280px;
    }
    .bn-controls__right {
      font-size: 2.0em;
      text-align: right;
      a {
        color: $clr__white;
        &:visited {
          color: $clr__white;
        }
        &:hover {
          color: rgba($clr__white, 0.8);
        }
      }
    }
  }
  // burger menu
  .bn-burger-menu {
    cursor: pointer;
    display: inline-block;
    pointer-events: initial;
    position: absolute;
    color: $clr__holzer--logo;
    &::before {
      content: "";
      position: absolute;
      height: 150%;
      width: 150%;
      top: -26%;
      left: -25%;
      z-index: -1;
      background-color: $clr__ci--primary;
      border: 1px solid $clr__holzer--logo;
    }
    .bn-icon {
      font-size: 5.0em;
    }
    &.bn-sidebar-trigger--act {
      display: none;
    }
    &:hover {
      color: $clr-_cta--hover;
    }
  }
  // sidebar
  .bn-sidebar {
    @include header--sidebar();
    pointer-events: initial;
    &__head {
      display: flex;
      align-items: center;
      .bn-lang-menu.bn-lang-menu--aside {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
      }
    }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      .bn-main-logo.bn-main-logo--aside {
        padding-left: 30px;
        height: 80px;
        width: 135px;
        img {
          height: 50px !important;
        }
      }
    }
  }
  // main logo
  .bn-main-logo {
    &.bn-main-logo--above {
      display: inline-block;
      pointer-events: initial;
      .bn-main-logo__image {
        img {
          @include img--rendering-crisp-edges();
          max-width: 240px;
        }
        &.bn-main-logo__image--small {
          display: none;
        }
        &.bn-main-logo__image--mobile {
          display: none;
        }
      }
    }
    &.bn-main-logo--aside {
      margin-top: 0 !important;
      padding-left: 50px;
    }
  }

  //add arrow to menu with submenu
  .bn-main-menu__item--has-children .bn-main-menu__title::after{
    content: "\e903";
    font-family: 'bn-icons' !important;
    font-size: .8em;
    margin-left: 5px;
  }

  // tel
  .bn-tel {
    &.bn-tel--above {
      display: inline-block;
      pointer-events: initial;
      white-space: nowrap;
      background-color: $clr__ci--primary;
      border: 1px solid $clr__holzer--logo;
      padding: 5px 12px;
      margin-bottom: 5px;
      a:hover {
        color: rgba($clr__white, 0.8);
      }
      .break {
        display: none;
      }
    }
  }
  .bn-lang-menu {
    &.bn-lang-menu--vista {
      display: inline-flex;
      padding: 10px 12px;
      background-color: #2F3B45;
      border: 1px solid $clr__holzer--logo;
      margin-left: 50px;
      ul {
        display: inline-flex;
        padding: 0;
        li {
          list-style-type: none;
          padding-right: 6px;
          margin-right: 6px;
          border-right: 1px solid white;
          line-height: 0.8;
          &:last-child {
            margin-right: 0;
            padding-right: 0;
            border-right: 0;
          }
          a:hover {
            color: rgba($clr__white, 0.8);
          }
        }
      }
    }
  }
  // no vista
  &.bn-header--no-vista {
    &:not(.bn-header--minimized) {
      // main logo
      .bn-main-logo {
        &.bn-main-logo--above {
          .bn-main-logo__image {
            img {
              height: 60px;
            }
          }
        }
      }
    }
  }
  // minimized
  &.bn-header--minimized {
    // controls
    .bn-controls {
      .bn-controls__center,
      .bn-controls__right {
        display: none;
      }
    }
  }

  /*
   * RESPONSIVE BEHAVIOR
   */

  // full screen on
  @media #{$mq__full-screen--on} {
    position: fixed;
  }
  // laptop
  @media #{$mq__laptop} {
    // controls
    .bn-controls {
      .bn-controls__center {
        max-width: 200px;
      }
    }
    // main logo
    .bn-main-logo {
      &.bn-main-logo--above {
        .bn-main-logo__image {
          img {
            max-width: 160px;
          }
        }
      }
    }
  }
}