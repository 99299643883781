/*  ==========================================================================
    QUICK REQUEST HORIZONTAL
    ========================================================================== */

@mixin quick-request--horizontal() {
  .bn_quickrequest_horizontal:not(.bn-dynformInit)>div:not(.bn-loader){
    display: none!important;
  }

  .bn_quickrequest_horizontal{
    width: 100%;
  }
  .bn-form__book {
    display: flex;
    align-items: center;
    height: 5em;
    background-color: $clr__cta--primary;
    border: 1px solid $clr__cta--primary;
    border-radius: 5em;
    margin: 1.6em 0;
    > a {
      width: 8em;
      font-size: 1.5em;
      font-weight: $typo__fw--medium;
      text-align: center;
      text-transform: uppercase;
      color: $clr__ci--primary;
    }
    &:hover {
      background-color: $clr__cta--hover;
      border-color: $clr__cta--hover;
    }
  }

  //laptop

  @media #{$mq__desktop--small} {
    .bn-form__book {
      height: 8em;
      border-radius: 3.5em;
      > a {
        font-size: 2em;
      }
    }
  }
  @media #{$mq__laptop} {
    .bn-form__book {
      height: 9em;
      > a {
        font-size: 2.2em;
      }
    }
  }

}