/*  ==========================================================================
    MOBILE HEADER V3
    ========================================================================== */

@mixin mobile-header--v3() {
  @include mobile-header--v1();
  background: linear-gradient(rgba($clr__black,0.4), transparent);
  border-bottom-width: 0;
  // lang menu
  .bn-lang-menu {
    &.bn-lang-menu--above {
      ul {
        color: $clr__white;
        li {
          a {
            color: $clr__white;
            &:visited {
              color: $clr__white;
            }
            &:hover {
              color: $clr__ci--primary;
            }
          }
        }
      }
    }
  }
  // burger menu
  .bn-burger-menu_wrapper {
    &.bn-burger-menu_wrapper--top-references-v3 {
      @media only screen and (max-width: $bn-grid__bp--tablet) {
        display: none !important;
      }
    }
    .bn-burger-menu {
      color: $clr__white;

      &:hover {
        color: $clr__ci--primary;
      }
    }
  }
  // no vista
  &.bn-mobile-header--no-vista {
    &:not(.bn-mobile-header--minimized) {

      // burger menu
      .bn-burger-menu {
        &:hover {
          color: $clr__ci--secondary;
        }
      }
    }
  }
  // minimized
  &.bn-mobile-header--minimized {
    background: $clr__ci--primary;
    border-bottom-width: 1px;
  }
}