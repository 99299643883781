.bn-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(.js-active) {
    display: none;
  }

  .bn-popup__backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .bn-popup__container {
    position: relative;
    z-index: 1;
    max-width: calc(100% - 20px);
    width: 720px;
    border: 1px solid #dcc27d;
    display: flex;
    flex-direction: column;
    background-color: #2f3b45;
  }
  svg {
    width: 140px;
    height: 50px;
  }

  .bn-popup__header {
    display: flex;
    padding: 5px 10px;
    align-items: center;
  }

  .bn-popup__header-line {
    width: 20px;
    height: 1px;
    background-color: #dcc27d;
  }

  i.bn-icon.bn-icon--close {
    font-size: 16px;
    padding: 0px 14px;
    cursor: pointer;
  }

  svg {
    padding: 0px 15px;
  }

  .bn-popup__header-line.bn-popup__header-line--long {
    flex: 1;
  }

  .bn-popup__header > * {
    fill: #dcc27d !important;
    color: #dcc27d;
  }

  .bn-popup__body {
    flex: 1;
    padding: 0 15px;
  }

  .bn-popup__title {
    font-size: 2.8em;
    color: #dcc27d;
  }

  .bn-popup__subtitle {
    font-size: 2.4em;
    color: #dcc27d;
  }

  .bn-popup__text {
    margin-top: 15px;
    font-size: 1.8em;
  }

  .bn-popup__footer {
    padding: 15px 20px;
  }
  .bn-popup__button {
    @include button--cta();
  }
}

