/*  ==========================================================================
    DOWNLOADBOXES
    ========================================================================== */

/*
 * COLORS
 */

//$module-clr__downloadboxes--bg:  $clr__ci--secondary;
//$module-clr__downloadboxes--border:  $clr__holzer--logo;
//$module-clr__downloadboxes--text-primary: $clr__ci--primary;
//$module-clr__downloadboxes--text-secondary: $clr__ci--primary;
//$module-clr__downloadboxes--accent: $clr__ci--primary;

$module-clr__downloadboxes--bg: rgba($clr__ci--primary-light, 0.5);
$module-clr__downloadboxes--border: $clr__holzer--logo;
$module-clr__downloadboxes--text-primary: $clr__white;
$module-clr__downloadboxes--text-secondary: $clr__white;
$module-clr__downloadboxes--accent: $clr__white;


/*
 * MODULE
 */

.bn-downloadboxes {
  > .bn-grid__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .bn-downloadboxes__item {
    flex: none !important;
    flex-grow: 1;
    margin-top: 20px;
    width: calc(50% - 10px);
    background-color: $module-clr__downloadboxes--bg;
    border: 1px solid $module-clr__downloadboxes--border;
    z-index: 1;

    > div {
      color: $module-clr__downloadboxes--text-primary;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      position: relative;
    }

    .bn-downloadboxes__image {
      display: block;
      overflow: hidden;
      width: 35%;

      img {
        @include img--cover-v2();
      }
    }

    .bn-downloadboxes__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      width: 65%;

      > div {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;

        > div {
          width: 100%;
        }

        &:first-child {
          align-items: flex-start;
          padding-bottom: 20px;
        }
      }

      .bn-downloadboxes__title {
        @include typo--subheadline($module-clr__downloadboxes--text-primary);
      }

      .bn-downloadboxes__link,
      .bn-downloadboxes__file {
        @include button--text($module-clr__downloadboxes--text-primary, $module-clr__downloadboxes--accent);
      }

      .bn-downloadboxes__link {
        margin-bottom: 10px;
      }
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

// tablet
@media #{$mq__tablet} {
  .bn-downloadboxes {
    .bn-downloadboxes__item {
      margin-top: 20px !important;
      width: 100%;

      &:first-child {
        margin-top: 0 !important;
      }
    }
  }
}

// phablet
@media #{$mq__phablet} {
  .bn-downloadboxes {
    .bn-downloadboxes__item {
      > div {
        display: block;
      }

      .bn-downloadboxes__image,
      .bn-downloadboxes__content {
        width: 100%;
      }

      .bn-downloadboxes__content {
        padding: 20px;
      }
    }
  }
}